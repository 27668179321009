import { OrderStatus } from './../types/enums'
import { DropDownItem, Order, Product, Room, Unit, User } from '../types'
import { DateTime } from 'luxon'
import { UserRoles, UserStatus } from '../types/enums'
import { v4 } from 'uuid'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneUnformatRegex = /[^\+\d]/g

const Formatters = {
    AuthCodeRegex: /^\d{6,6}$/,
    PhoneNumberRegex: /^[+\d]+(?:[\d-.\s()]*)$/,
    ZipCodeRegex: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    OrderStatuses_DropdownList: [
        { key: OrderStatus.Approved, label: 'Approved for Production' },
        { key: OrderStatus.Archived, label: 'Archived' },
        { key: OrderStatus.BackOrder, label: 'Back Order' },
        { key: OrderStatus.Cancelled, label: 'Cancelled' },
        { key: OrderStatus.Delivered, label: 'Delivered' },
        { key: OrderStatus.InProduction, label: 'In Production' },
        { key: OrderStatus.Open, label: 'Open' },
        { key: OrderStatus.Quoting, label: 'Quoting' },
        { key: OrderStatus.ReadyForReview, label: 'Ready for Review' },
        { key: OrderStatus.Reviewed, label: 'Reviewed' },
        { key: OrderStatus.Submitted, label: 'Submitted' },
        { key: OrderStatus.Shipped, label: 'Shipped' },
    ] as DropDownItem[],
    formatUserFullName: (user: User): string => {
        let str = `${user.firstName} ${user.lastName}`;

        return str.trim();
    },
    formatPhoneNumber: (str: string | undefined): string => {
        if (!str) {
            return ''
        }

        let cleaned = ('' + str).replace(/\D/g, '');
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return '';
    },
    unformatPhoneNumber: (str: string | undefined): string => {
        if (!str) {
            return ''
        }

        return str.replace(phoneUnformatRegex, '')
    },
    formatLineItemNumber: (roomIndex: number, itemIndex: number): string => {
        let roomStr = (roomIndex + 1).toString()
        let itemNo = (itemIndex + 1).toString().padStart(2, '0')
        let str = roomStr + itemNo
        return str
    },
    formatProductDimensions: (product: Product): string => {
        // if (product.depth.toLowerCase().includes('n/a')) {
        //     return 'N/A'
        // }
        let width = product.width.toLowerCase().includes('n/a') ? 'N/A' : product.width + '"'
        let height = product.height.toLowerCase().includes('n/a') ? 'N/A' : product.height + '"'
        let depth = product.depth.toLowerCase().includes('n/a') ? 'N/A' : product.depth + '"'
        return `${width} x ${height} x ${depth}`
    },
    isLocationRequiredForUser: (role: UserRoles): boolean => {
        return [UserRoles.Designer, UserRoles.LocationBillingContact, UserRoles.LocationManager].includes(role)
    },
    isMaTeamUser: (user: User | null): boolean => {
        return !!user && [UserRoles.Accountant, UserRoles.Admin, UserRoles.CustomerService].includes(user.role)
    },
    isOrderEmpty: (order: Partial<Order> | null): boolean => {
        if (!order) {
            return true
        }

        if (!!order.jobName) {
            return false
        }

        return true
    },
    isValidEmail: (str: string): boolean => {
        return !!str && emailRegex.test(str);
    },
    isValidMfaCode: (str: string): boolean => {
        return !!str && Formatters.AuthCodeRegex.test(str);
    },
    isValidPassword: (str: string): boolean => {
        return !!str && str.toString().length >= 8;
    },
    isValidPhoneNumber: (str: string): boolean => {
        return !!str && Formatters.PhoneNumberRegex.test(str);
    },
    FormatAddressParts: (obj: any): string => {
        let parts = [obj.address1, obj.address2, obj.city, obj.state];
        let str = parts.filter(el => !!el).join(', ');
        return str + (obj.zip ? ' ' + obj.zip : '');
    },
    formatISODate: (value: string, format: string = 'MMMM d, yyyy', invalidStr: string = '-'): string => {
        let iso = DateTime.fromISO(value)
        if (!iso.isValid) {
            return invalidStr
        }
        return iso.toFormat(format)
    },
    formatNotificationTimeStamp: (timestamp: string): string => {
        const dt = DateTime.fromISO(timestamp)
        if (!dt.isValid) {
            return 'Error'
        }

        if (dt.toISODate() === DateTime.now().toISODate()) {
            return 'Today • ' + dt.toFormat('h:mm:ss')
        } else {
            return dt.toFormat('M/d • h:mm:ss')
        }
    },
    formatMoney: (value: string | number | undefined, invalidStr?: string): string => {
        if (!value || value === 0 || value === "0") {
            return invalidStr || "$0"
        }

        let parts = value.toString().split(".")
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        if (parts[1] && parts[1].length === 1) {
            parts[1] = parts[1] + "0"
        }

        let str = parts.join(".")
        let dollarsign = str.indexOf("$")

        if (dollarsign < 0) {
            return "$" + str
        }

        return str
    },
    getEmptyRoom: (): Room => {
        const blankUnit: Unit = {key: '', label: '', panelProfile: '', insideProfile: '', outsideProfile: '', frameWidth: ''}
        return {
            key: v4(),
            construction: '',
            exteriorFinish: '',
            interiorFinish: '',
            lineItems: [],
            wood: '',
            color: '',
            glaze: '',
            name: '',
            sheen: '20% Satin',
            baseDoor: {...blankUnit},
            largeDrawer: {...blankUnit},
            smallDrawer: {...blankUnit},
        }
    },
    getOrderStatus: (value: OrderStatus): string => {
        switch (value) {
            case OrderStatus.Approved:
                return 'Approved for Production'
            case OrderStatus.Archived:
                return 'Archived'
            case OrderStatus.BackOrder:
                return 'Back Order'
            case OrderStatus.Cancelled:
                return 'Cancelled'
            case OrderStatus.InProduction:
                return 'In Production'
            case OrderStatus.Open:
                return 'Open'
            case OrderStatus.ReadyForReview:
                return 'Ready for Review'
            case OrderStatus.Reviewed:
                return 'Reviewed'
            case OrderStatus.Quoting:
                return 'Quoting'
            case OrderStatus.Submitted:
                return 'Submitted'
            case OrderStatus.Shipped:
                return 'Shipped'
            case OrderStatus.Delivered:
                return 'Delivered'
            default:
                console.error(`Unknown Order Status encountered: "${value}". Please report this issue via the Report a Problem page.`)
                return 'Error'
        }
    },
    getOrderStatusColor: (value: OrderStatus): string => {
        switch (value) {
            case OrderStatus.Approved:
                return 'var(--ui-red)'
            case OrderStatus.Archived:
                return 'var(--mid-grey)'
            case OrderStatus.BackOrder:
                return '#ff0000'
            case OrderStatus.Cancelled:
                return 'var(--ui-orange)'
            case OrderStatus.InProduction:
                return 'var(--ui-dark-blue)'
            case OrderStatus.Open:
                return 'var(--ui-purple)'
            case OrderStatus.ReadyForReview:
                return 'var(--ui-green)'
            case OrderStatus.Reviewed:
                return 'var(--ui-pink)'
            case OrderStatus.Quoting:
                return 'var(--ui-gold)'
            case OrderStatus.Submitted:
                return 'var(--ui-dark-blue)'
            case OrderStatus.Shipped:
                return 'var(--ui-teal)'
            case OrderStatus.Delivered:
                return 'var(--ui-ma-blue)'
            default:
                return '#5e28ff'
        }
    },
    getUserRole: (value: UserRoles): string => {
        switch (value) {
            case UserRoles.Admin:
                return 'Admin'
            case UserRoles.CustomerService:
                return 'Customer Service Rep'
            case UserRoles.Accountant:
                return 'Accountant'
            case UserRoles.FieldRep:
                return 'Field Rep'
            case UserRoles.Dealer:
                return 'Dealer'
            case UserRoles.Designer:
                return 'Designer'
            case UserRoles.LocationManager:
                return 'Location Manager'
            case UserRoles.LocationBillingContact:
                return 'Billing Contact'
            default:
                return 'Error'
        }
    },
    getUserStatus: (value: UserStatus): string => {
        switch (value) {
            case UserStatus.Active:
                return 'Active'
            case UserStatus.Suspended:
                return 'Active'
            case UserStatus.Deleted:
                return 'Active'
            default:
                return 'Error'
        }
    },
    updateRoomWithinOrderRooms: (order: Partial<Order> | null, room: Room): Room[] => {
        let rooms = []
        if (!!order && order.rooms && order.rooms.length > 0) {
            for (let existingRoom of order.rooms) {
                if (existingRoom.key === room.key) {
                    rooms.push(room)
                } else {
                    rooms.push(existingRoom)
                }
            }
        } else {
            rooms.push(room)
        }

        // Re-number line items, in case they've changed
        let final = []
        for (let r = 0; r < rooms.length; r++) {
            if (!!rooms[r].lineItems && rooms[r].lineItems.length > 0) {
                let updatedItems = []
                for (let i = 0; i < rooms[r].lineItems.length; i++) {
                    let item = Object.assign({}, rooms[r].lineItems[i])
                    item.itemNumber = Formatters.formatLineItemNumber(r, i)
                    updatedItems.push(Object.assign({}, item))
                }
                let room = Object.assign({}, rooms[r])
                room.lineItems = updatedItems
                final.push(room)
            } else {
                final.push(Object.assign({}, rooms[r]))
            }
        }

        return final
    }
}

export default Formatters